import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faListUl } from '@fortawesome/pro-solid-svg-icons/faListUl';

import withComponentName from '../../../decorators/withComponentName';

import Styles from '../styles.module.scss';

const MeetupSocialSection = () => (
  <div className={Styles['meetup-social-section']}>
    <div className={Styles.section}>
      <a
        href="https://twitter.com/intent/user?screen_name=CityFalcon"
        aria-label="CityFalcon on Twitter"
        className={Styles.twitter}
        target="_blank"
        rel="noreferrer"
      >
        <div className={Styles.icon}>
          <FontAwesomeIcon icon={faTwitter} />
        </div>
        <span>Follow us on Twitter</span>
      </a>
      <a
        href="https://www.facebook.com/cityfalconfollow"
        aria-label="CityFalcon on Facebook"
        className={Styles.facebook}
        target="_blank"
        rel="noreferrer"
      >
        <div className={Styles.icon}>
          <FontAwesomeIcon icon={faFacebookF} />
        </div>
        <span>
          Discuss ideas and
          <br />
          {' '}
          opportunities in our group
        </span>
      </a>
      <a
        href="https://docs.google.com/spreadsheets/d/1jyDkmynLJematzmA5hNsN1LqdgrFoBd4iwue4w35U3A/edit#gid=0"
        className={Styles.list}
        target="_blank"
        rel="noreferrer"
      >
        <div className={Styles.icon}>
          <FontAwesomeIcon icon={faListUl} />
        </div>
        <span>
          Check performance
          <br />
          of mentioned stocks
        </span>
      </a>
    </div>
  </div>
);

export default withComponentName(MeetupSocialSection);
